import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Cookies = ({ data }) => (
  <Layout>
    <Seo title={data?.wpPage?.title} />
    <main>
      <section className="page-default">
        <div className="container-fluid">
          <div className="page-default--inner">
            <h1>{data?.wpPage?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data?.wpPage?.content }} />
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMDc=" }) {
      title
      content
    }
  }
`

export default Cookies
